var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CRow",
    [
      _c(
        "CCol",
        { attrs: { md: "3", lg: "4" } },
        [
          _c(
            "CWidgetBrand",
            {
              staticClass: "w-100",
              attrs: {
                color: "primary",
                rightHeader: String(_vm.userData.data.customerCount),
                rightFooter: "Kunden",
                leftHeader: String(_vm.userData.data.ownerCount),
                leftFooter: "Vermieter"
              }
            },
            [
              _c(
                "router-link",
                { attrs: { to: { name: "AdminCustomerIndex" } } },
                [
                  _c("CIcon", {
                    staticClass: "my-4",
                    attrs: { name: "cil-people", height: "64" }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm.userData.loading
            ? _c("CElementCover", { attrs: { opacity: 0.8 } })
            : _vm._e()
        ],
        1
      ),
      _c(
        "CCol",
        { attrs: { md: "3", lg: "4" } },
        [
          _c(
            "CWidgetBrand",
            {
              staticClass: "w-100",
              attrs: {
                color: "primary",
                rightHeader: String(_vm.provisionData.data.provisionLast30Days),
                rightFooter: "Letzter Monat",
                leftHeader: String(_vm.provisionData.data.provisionTotal),
                leftFooter: "Insgesamt"
              }
            },
            [
              _c(
                "router-link",
                { attrs: { to: { name: "AdminMoneyTransactionIndex" } } },
                [
                  _c("CIcon", {
                    staticClass: "my-4",
                    attrs: { name: "cil-dollar", height: "64" }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm.provisionData.loading
            ? _c("CElementCover", { attrs: { opacity: 0.8 } })
            : _vm._e()
        ],
        1
      ),
      _c(
        "CCol",
        { attrs: { md: "3", lg: "4" } },
        [
          _c(
            "CWidgetBrand",
            {
              staticClass: "w-100",
              attrs: {
                color: "primary",
                rightHeader: String(_vm.orderData.data.orderCount),
                rightFooter: "Insgesamt",
                leftHeader: String(_vm.orderData.data.successOrderCount),
                leftFooter: "Erfolgreich"
              }
            },
            [
              _c(
                "router-link",
                { attrs: { to: { name: "AdminOrderIndex" } } },
                [
                  _c("CIcon", {
                    staticClass: "my-4",
                    attrs: { name: "cil-cart", height: "64" }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm.orderData.loading
            ? _c("CElementCover", { attrs: { opacity: 0.8 } })
            : _vm._e()
        ],
        1
      ),
      _c(
        "CCol",
        { attrs: { md: "3", lg: "4" } },
        [
          _c(
            "CWidgetBrand",
            {
              staticClass: "w-100",
              attrs: {
                color: "primary",
                rightHeader: String(
                  _vm.orderForecastData.data.orderCountLastMonth
                ),
                rightFooter: "letzer Monat",
                leftHeader: String(
                  _vm.orderForecastData.data.orderCountThisMonth
                ),
                leftFooter: "dieser Monat (bisher)"
              }
            },
            [
              _c(
                "router-link",
                { attrs: { to: { name: "AdminOrderIndex" } } },
                [
                  _c("CIcon", {
                    staticClass: "my-4",
                    attrs: { name: "cil-cart", height: "64" }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm.orderForecastData.loading
            ? _c("CElementCover", { attrs: { opacity: 0.8 } })
            : _vm._e()
        ],
        1
      ),
      _c(
        "CCol",
        { attrs: { md: "3", lg: "4" } },
        [
          _c(
            "CWidgetBrand",
            {
              staticClass: "w-100",
              attrs: {
                color: "primary",
                rightHeader: String(
                  _vm.orderForecastData.data.orderCountThisMonthForecast
                ),
                rightFooter: "Dieser Monat",
                leftHeader: String(
                  _vm.orderForecastData.data.orderCountNextMonthForecast
                ),
                leftFooter: "Nächster Monat"
              }
            },
            [
              _c(
                "router-link",
                { attrs: { to: { name: "AdminOrderIndex" } } },
                [
                  _c("CIcon", {
                    staticClass: "my-4",
                    attrs: { name: "cil-calendar-plus", height: "64" }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm.orderForecastData.loading
            ? _c("CElementCover", { attrs: { opacity: 0.8 } })
            : _vm._e()
        ],
        1
      ),
      _c(
        "CCol",
        { attrs: { md: "12", lg: "6" } },
        [
          _c("CChartBar", {
            staticStyle: { height: "300px" },
            attrs: {
              datasets: [
                {
                  data: _vm.historyOrderChart.values,
                  backgroundColor: "#E55353",
                  label: "Orders"
                }
              ],
              labels: _vm.historyOrderChart.labels,
              options: { maintainAspectRatio: false }
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }